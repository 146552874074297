import {
	Paper,
	Text,
	TextInput,
	Textarea,
	Button,
	Group,
	SimpleGrid,
	createStyles,
	rem,
	Container,
} from '@mantine/core';
import { IconBrandWhatsapp, IconMailFast, IconMapPin, IconPhone, IconSun } from '@tabler/icons-react';

const useStyles = createStyles((theme) => {
	const BREAKPOINT = theme.fn.smallerThan('sm');

	return {
		paperWrapper: {
			height: '77vh',
			display: 'flex',
			alignItems: 'center',
			[BREAKPOINT]: {
				alignItems: 'start',
			},
		},
		wrapper: {
			display: 'flex',
			width: '100%',
			backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
			borderRadius: theme.radius.lg,
			padding: rem(4),
			border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2]}`,

			[BREAKPOINT]: {
				flexDirection: 'column',
			},
		},

		form: {
			boxSizing: 'border-box',
			flex: 1,
			padding: theme.spacing.xl,
			paddingLeft: `calc(${theme.spacing.xl} * 2)`,
			borderLeft: 0,

			[BREAKPOINT]: {
				padding: theme.spacing.md,
				paddingLeft: theme.spacing.md,
			},
		},

		fields: {
			marginTop: rem(-12),
		},

		fieldInput: {
			flex: 1,

			'& + &': {
				marginLeft: theme.spacing.md,

				[BREAKPOINT]: {
					marginLeft: 0,
					marginTop: theme.spacing.md,
				},
			},
		},

		fieldsGroup: {
			display: 'flex',

			[BREAKPOINT]: {
				flexDirection: 'column',
			},
		},

		contacts: {
			boxSizing: 'border-box',
			position: 'relative',
			borderRadius: theme.radius.lg,
			// backgroundImage: `url(${bg.src})`,
			// backgroundSize: 'cover',
			// backgroundPosition: 'center',
			backgroundColor: theme.colors.brand[5],
			border: `${rem(1)} solid transparent`,
			padding: theme.spacing.xl,
			display: 'flex',
			flexDirection: 'column',
			flex: `0 0 ${rem(280)}`,

			[BREAKPOINT]: {
				marginBottom: theme.spacing.sm,
				paddingLeft: theme.spacing.md,
			},
		},

		title: {
			marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
			fontFamily: `Greycliff CF, ${theme.fontFamily}`,

			[BREAKPOINT]: {
				marginBottom: theme.spacing.xl,
			},
		},

		control: {
			[BREAKPOINT]: {
				flex: 1,
			},
		},

		contactGroup: {
			flexGrow: 1,
			color: theme.white,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-around',
		},
	};
});

const Contact = () => {
	const { classes } = useStyles();

	return (
		<Paper className={classes.paperWrapper} shadow="md" radius="lg">
			<Container className={classes.wrapper}>
				<div className={classes.contacts}>
					<Text fz="lg" fw={700} className={classes.title} c="#fff">
						Contact information
					</Text>

					<div className={classes.contactGroup}>
						<Group>
							<div>
								<IconMailFast />
							</div>
							<div>
								<Text size="xs">Email</Text>
								<Text>infinarygeeks@gmail.com</Text>
							</div>
						</Group>
						<Group>
							<div>
								<IconPhone />
							</div>
							<div>
								<Text size="xs">Phone</Text>
								<Text>+977 9861763200</Text>
							</div>
						</Group>
						<Group>
							<div>
								<IconBrandWhatsapp />
							</div>
							<div>
								<Text size="xs">Whatsapp</Text>
								<Text>+977 9843776792</Text>
							</div>
						</Group>
						<Group>
							<div>
								<IconMapPin />
							</div>
							<div>
								<Text size="xs">Address</Text>
								<Text>Nagarjun-10, Kathmandu</Text>
							</div>
						</Group>
						<Group>
							<div>
								<IconSun />
							</div>
							<div>
								<Text size="xs">Working hours</Text>
								<Text>9 a.m. - 5 p.m.</Text>
							</div>
						</Group>
					</div>
				</div>

				<form className={classes.form} onSubmit={(event) => event.preventDefault()}>
					<Text fz="lg" fw={700} className={classes.title}>
						Get in touch
					</Text>

					<div className={classes.fields}>
						<SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
							<TextInput label="Your name" placeholder="Your name" />
							<TextInput label="Your email" placeholder="hello@mantine.dev" required />
						</SimpleGrid>

						<TextInput mt="md" label="Subject" placeholder="Subject" required />

						<Textarea mt="md" label="Your message" placeholder="Please include all relevant information" minRows={3} />

						<Group position="right" mt="md">
							<Button type="submit" className={classes.control}>
								Send message
							</Button>
						</Group>
					</div>
				</form>
			</Container>
		</Paper>
	);
};

export default Contact;

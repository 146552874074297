import { MantineThemeOverride } from '@mantine/core';

interface Iargs {
	[key: string]: any;
}

type themeFunctionType = (args: Iargs) => MantineThemeOverride;

const themeFunction: themeFunctionType = (args) => {
	return {
		colorScheme: args.theme === 'dark' ? 'dark' : 'light',
		fontFamily: 'Roboto, sans-serif',
		headings: {
			fontFamily: 'Raleway, sans-serif',
		},
		colors: {
			slate: [
				'#f8fafc',
				'#f1f5f9',
				'#e2e8f0',
				'#cbd5e1',
				'#94a3b8',
				'#64748b',
				'#475569',
				'#334155',
				'#1e293b',
				'#0f172a',
			],
			brand: [
				'#A1B5C8',
				'#6A97C1',
				'#4B8AC5',
				'#2C7ECA',
				'#1673CA',
				'#0069CC',
				'#125EA5',
				'#1E5487',
				'#244B70',
				'#28445E',
			],
			secondary: [
				'#474747',
				'#3B3B3B',
				'#353535',
				'#303030',
				'#2C2C2C',
				'#282828',
				'#242424',
				'#202020',
				'#1D1D1D',
				'#1A1A1A',
			],
			brand2: [
				'#A4B8BB',
				'#78A4AA',
				'#619EA7',
				'#4D98A2',
				'#3C919D',
				'#2C8C9A',
				'#317781',
				'#34656D',
				'#34575D',
				'#324C50',
			],
			secondary2: [
				'#606060',
				'#4F4F4F',
				'#484848',
				'#414141',
				'#3B3B3B',
				'#363636',
				'#313131',
				'#2C2C2C',
				'#272727',
				'#232323',
			],
		},
		primaryColor: 'brand',
	};
};

export default themeFunction;

import { createStyles, Title, Text, Card, SimpleGrid, Container, rem } from '@mantine/core';
import { IconCode, IconTool, IconHeadset } from '@tabler/icons-react';

const mockdata = [
	{
		title: 'Development',
		description: 'Creatung custom software solutions to address specific business needs',
		icon: IconCode,
	},
	{
		title: 'Technology',
		description: 'Utilizing the latest tools and technologies to create innovative and scalable software products',
		icon: IconTool,
	},
	{
		title: 'Support',
		description:
			'Providing ongoing technical support, maintenance, and updates to ensure the software remains functional and secure.',
		icon: IconHeadset,
	},
];

const useStyles = createStyles((theme) => ({
	title: {
		fontSize: rem(34),
		fontWeight: 900,

		[theme.fn.smallerThan('sm')]: {
			fontSize: rem(24),
		},
	},

	description: {
		maxWidth: 600,
		margin: 'auto',

		'&::after': {
			content: '""',
			display: 'block',
			backgroundColor: theme.fn.primaryColor(),
			width: rem(45),
			height: rem(2),
			marginTop: theme.spacing.sm,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},

	card: {
		border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]}`,
	},

	cardTitle: {
		'&::after': {
			content: '""',
			display: 'block',
			backgroundColor: theme.fn.primaryColor(),
			width: rem(45),
			height: rem(2),
			marginTop: theme.spacing.sm,
		},
	},
}));

const Features = () => {
	const { classes, theme } = useStyles();
	const features = mockdata.map((feature) => (
		<Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl">
			<feature.icon size={rem(50)} stroke={2} color={theme.fn.primaryColor()} />
			<Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
				{feature.title}
			</Text>
			<Text fz="sm" c="dimmed" mt="sm">
				{feature.description}
			</Text>
		</Card>
	));

	return (
		<Container py="xl">
			<Title order={2} className={classes.title} ta="center" mt="sm">
				Discover the Power of Infinary Geeks
			</Title>

			<Text c="dimmed" className={classes.description} ta="center" mt="md">
				{/* Our company offers a range of powerful tools to streamline and autoate your business processes. From project
				management and collaboration to data analysis and reporting, our solutions are designed to help you work smarter
				and faster. */}
				Work smarter and faster with our comprehensive software suite. Our powerful tools streamline and automate
				business processes including project management, collaboration, data analysis, and reporting.
			</Text>

			<SimpleGrid cols={3} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
				{features}
			</SimpleGrid>
		</Container>
	);
};

export default Features;

import React, { useState } from 'react';
import { MantineProvider, ColorSchemeProvider, createStyles, Text } from '@mantine/core';
import themeFunction from './_utilities/theme';
import { Routes, Route } from 'react-router-dom';
// layouts
import HeaderComponent from './layouts/Header';
import Footer from './layouts/Footer';
// pages
import Home from './pages/Home';
import About from './pages/About';
import Careers from './pages/Careers';
import NotFound404 from './pages/NotFound404';
import Contact from './pages/Contact';

import { IconSquareX } from '@tabler/icons-react';

const useStyles = createStyles((theme) => {
	return {
		siteUnderConstruction: {
			backgroundColor: '#e11d48',
			color: 'white',
			fontSize: 14,
			padding: '4px 8px',
			position: 'relative',
		},
		closeIcon: {
			float: 'right',
			cursor: 'pointer',
		},
	};
});

function App() {
	const [theme, setTheme] = useState<'dark' | 'light'>('dark');
	const { classes } = useStyles();
	const [show, setShow] = useState<boolean>(true);

	return (
		<MantineProvider theme={themeFunction({ theme: theme })} withGlobalStyles withNormalizeCSS>
			<ColorSchemeProvider colorScheme={theme} toggleColorScheme={(value: 'dark' | 'light') => setTheme(value)}>
				{/* <ToggleTheme /> */}
				{show && (
					<div className={classes.siteUnderConstruction}>
						<IconSquareX className={classes.closeIcon} onClick={() => setShow(false)} />
						<Text>
							We apologize for the inconvenience, but this website is currently undergoing maintenance. Please check
							back soon for updates or feel free to reach out to our administrator at{' '}
							<Text component="span" underline fw="bold">
								infinarygeeks@gmail.com
							</Text>{' '}
							if you have any questions or concerns. Thank you for your patience!
						</Text>
					</div>
				)}
				<HeaderComponent />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/careers" element={<Careers />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="*" element={<NotFound404 />} />
				</Routes>
				<Footer />
			</ColorSchemeProvider>
		</MantineProvider>
	);
}

export default App;

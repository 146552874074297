import { createStyles, Title, Text, Container, rem, Group } from '@mantine/core';

const useStyles = createStyles((theme) => ({
	wrapper: {
		position: 'relative',
		display: 'grid',
		placeContent: 'center',
		height: '77vh',

		[theme.fn.smallerThan('sm')]: {
			paddingTop: rem(80),
			paddingBottom: rem(60),
		},
	},

	inner: {
		position: 'relative',
		zIndex: 1,
	},

	dots: {
		position: 'absolute',
		color: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],

		[theme.fn.smallerThan('sm')]: {
			display: 'none',
		},
	},

	dotsLeft: {
		left: 0,
		top: 0,
	},

	title: {
		textAlign: 'center',
		fontWeight: 800,
		fontSize: rem(40),
		letterSpacing: -1,
		color: theme.colorScheme === 'dark' ? theme.white : theme.black,
		marginBottom: theme.spacing.xs,
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,

		[theme.fn.smallerThan('xs')]: {
			fontSize: rem(28),
			textAlign: 'left',
		},
	},

	highlight: {
		color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6],
	},

	description: {
		textAlign: 'center',

		[theme.fn.smallerThan('xs')]: {
			textAlign: 'left',
			fontSize: theme.fontSizes.md,
		},
	},

	controls: {
		marginTop: theme.spacing.lg,
		display: 'flex',
		justifyContent: 'center',

		[theme.fn.smallerThan('xs')]: {
			flexDirection: 'column',
		},
	},

	control: {
		'&:not(:first-of-type)': {
			marginLeft: theme.spacing.md,
		},

		[theme.fn.smallerThan('xs')]: {
			height: rem(42),
			fontSize: theme.fontSizes.md,

			'&:not(:first-of-type)': {
				marginTop: theme.spacing.md,
				marginLeft: 0,
			},
		},
	},
}));

const Careers = () => {
	const { classes } = useStyles();

	return (
		<Container className={classes.wrapper} size={1400} py={160}>
			<div className={classes.inner}>
				<Title className={classes.title}>
					Choose your{' '}
					<Text component="span" weight="bold" className={classes.highlight} inherit>
						career
					</Text>{' '}
					path with us.
				</Title>

				<Container p={0} size={600}>
					<Text size="lg" color="dimmed" className={classes.description}>
						Discover your career potential with{' '}
						<Text component="span" weight="bold" className={classes.highlight}>
							Infinary Geeks
						</Text>
						. We offer diverse roles and exciting projects to help you grow and succeed in the world of technology. Join
						us today.
					</Text>
				</Container>

				<Group py={48}>
					<Text align="center" color="dimmed">
						Interested in exploring career options with{' '}
						<Text component="span" weight="bold" className={classes.highlight}>
							Infinary Geeks
						</Text>
						? Stay connected with us for the latest job openings and career opportunities. Follow us on social media,
						visit our careers page, or contact us directly to learn more about how you can join our team and grow your
						career with us.
					</Text>
				</Group>
			</div>
		</Container>
	);
};

export default Careers;

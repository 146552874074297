import React from 'react';
import Landing from './Landing';
import Features from './Feature';
import Technologies from './Technologies';

const Home = () => {
	return (
		<div style={{ maxWidth: '100vw', overflowX: 'hidden' }}>
			<Landing />
			<Features />
			<Technologies />
		</div>
	);
};

export default Home;

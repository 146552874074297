import { createStyles, Container, Group, ActionIcon, rem, Image, useMantineColorScheme } from '@mantine/core';
import {
	IconBrandYoutube,
	IconBrandInstagram,
	IconBrandGithub,
	IconBrandLinkedin,
	IconBrandFacebook,
	IconBrandWhatsapp,
} from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
	footer: {
		marginTop: rem(120),
		borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
	},

	inner: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: theme.spacing.xl,
		paddingBottom: theme.spacing.xl,

		[theme.fn.smallerThan('xs')]: {
			flexDirection: 'column',
		},
	},

	links: {
		[theme.fn.smallerThan('xs')]: {
			marginTop: theme.spacing.md,
		},
	},
	logoGroup: {
		backgroundColor: theme.white,
		// height: 60,
		padding: 8,
		borderRadius: '0 50% 50% 50%',
	},
}));

const Footer = () => {
	const { classes } = useStyles();
	const { colorScheme } = useMantineColorScheme();

	return (
		<div className={classes.footer}>
			<Container className={classes.inner}>
				<Group>
					<Image
						src={colorScheme === 'dark' ? '/assets/logo_full white.png' : '/assets/logo_full.png'}
						width={100}
						alt="logo"
					/>
				</Group>
				<Group spacing={0} className={classes.links} position="right" noWrap>
					<ActionIcon size="lg">
						<IconBrandFacebook size="1.05rem" stroke={1.5} />
					</ActionIcon>
					<ActionIcon size="lg">
						<IconBrandWhatsapp size="1.05rem" stroke={1.5} />
					</ActionIcon>
					<ActionIcon size="lg">
						<IconBrandYoutube size="1.05rem" stroke={1.5} />
					</ActionIcon>
					<ActionIcon size="lg">
						<IconBrandInstagram size="1.05rem" stroke={1.5} />
					</ActionIcon>
					<ActionIcon size="lg">
						<IconBrandGithub size="1.05rem" stroke={1.5} />
					</ActionIcon>
					<ActionIcon size="lg">
						<IconBrandLinkedin size="1.05rem" stroke={1.5} />
					</ActionIcon>
				</Group>
			</Container>
		</div>
	);
};

export default Footer;

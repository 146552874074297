import React, { useLayoutEffect } from 'react';
import { Switch, Group, useMantineColorScheme, useMantineTheme, createStyles } from '@mantine/core';
import { IconSun, IconMoonStars } from '@tabler/icons-react';

const useStyles = createStyles((theme) => {
	return {
		themeToggler: {
			[theme.fn.smallerThan('md')]: {
				position: 'fixed',
				margin: 0,
				right: 0,
				top: 60,
				zIndex: 100,
				transform: 'rotate(90deg)  translateY(-50%) translateX(50%)',
			},
		},
	};
});

const ToggleTheme = () => {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const theme = useMantineTheme();
	const { classes } = useStyles();

	useLayoutEffect(() => {
		const localTheme = window.localStorage.getItem('ig-theme');
		if (localTheme === 'light') {
			toggleColorScheme('light');
		} else toggleColorScheme('dark');
	}, [toggleColorScheme]);

	const handleThemeChange = () => {
		if (colorScheme === 'dark') {
			toggleColorScheme('light');
			window.localStorage.setItem('ig-theme', 'light');
		} else {
			toggleColorScheme('dark');
			window.localStorage.setItem('ig-theme', 'dark');
		}
	};

	return (
		<Group position="right" mx={16} className={classes.themeToggler}>
			<Switch
				checked={colorScheme === 'dark'}
				onChange={() => handleThemeChange()}
				size="lg"
				onLabel={<IconSun color={theme.white} size="1.25rem" stroke={1.5} />}
				offLabel={<IconMoonStars color={theme.colors.gray[6]} size="1.25rem" stroke={1.5} />}
			/>
		</Group>
	);
};

export default ToggleTheme;

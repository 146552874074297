import { FC } from 'react';
import {
	createStyles,
	Header,
	Container,
	Group,
	Burger,
	rem,
	Image,
	useMantineColorScheme,
	Transition,
	Paper,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Link, NavLink } from 'react-router-dom';
import ToggleTheme from '../../components/ToggleTheme';

const useStyles = createStyles((theme) => ({
	headerWrapper: {
		position: 'sticky',
		top: 0,
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
	},
	// logoGroup: {
	// 	height: '100%',
	// 	backgroundColor: theme.white,
	// 	borderRadius: '0 50% 50% 50%',
	// },
	dropdown: {
		position: 'absolute',
		top: rem(60),
		left: 0,
		right: 0,
		zIndex: 0,
		borderTopRightRadius: 0,
		borderTopLeftRadius: 0,
		borderTopWidth: 0,
		overflow: 'hidden',

		[theme.fn.largerThan('sm')]: {
			display: 'none',
		},
	},
	logo: {
		objectFit: 'cover',
		objectPosition: 'center',
	},

	links: {
		[theme.fn.smallerThan('xs')]: {
			display: 'none',
		},
	},

	burger: {
		[theme.fn.largerThan('xs')]: {
			display: 'none',
		},
	},

	link: {
		display: 'block',
		lineHeight: 1,
		padding: `${rem(8)} ${rem(12)}`,
		borderRadius: theme.radius.sm,
		textDecoration: 'none',
		color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
		fontSize: theme.fontSizes.sm,
		fontWeight: 500,

		'&:hover': {
			backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
		},
	},

	linkActive: {
		'&, &:hover': {
			backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
			color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
		},
	},
}));

interface Iheader {}

const HeaderComponent: FC<Iheader> = () => {
	const [opened, { toggle }] = useDisclosure(false);
	const { classes } = useStyles();
	const { colorScheme } = useMantineColorScheme();

	return (
		<Header height={60} className={classes.headerWrapper}>
			<Container className={classes.header}>
				<Link to="/">
					<Image
						className={classes.logo}
						width={60}
						src={colorScheme === 'dark' ? '/assets/logo white.png' : '/assets/logo.png'}
					/>
				</Link>
				<Group spacing={5} className={classes.links}>
					<Menu />
				</Group>

				<Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
				<Transition transition="pop-top-right" duration={200} mounted={opened}>
					{(styles) => (
						<Paper className={classes.dropdown} withBorder style={styles}>
							<Menu />
						</Paper>
					)}
				</Transition>
				<ToggleTheme />
			</Container>
		</Header>
	);
};

export default HeaderComponent;

const Menu = () => {
	const { classes, cx } = useStyles();
	return (
		<>
			<NavLink
				to="/about"
				end
				className={cx(classes.link, ({ isActive }: { isActive: boolean }) => (isActive ? classes.linkActive : ''))}
			>
				About Us
			</NavLink>
			<NavLink
				to="/careers"
				end
				className={cx(classes.link, ({ isActive }: { isActive: boolean }) => (isActive ? classes.linkActive : ''))}
			>
				Careers
			</NavLink>
			<NavLink
				to="/blogs"
				end
				className={cx(classes.link, ({ isActive }: { isActive: boolean }) => (isActive ? classes.linkActive : ''))}
			>
				Blogs
			</NavLink>
			<NavLink
				to="/contact"
				end
				className={cx(classes.link, ({ isActive }: { isActive: boolean }) => (isActive ? classes.linkActive : ''))}
			>
				Contact Us
			</NavLink>
		</>
	);
};

import { createStyles, Title, SimpleGrid, Text, Button, ThemeIcon, Grid, Col, rem, Container } from '@mantine/core';
import { IconBrandReact, IconBrandNextjs, IconBrandPython, IconBrandTypescript } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
	wrapper: {
		padding: `32px 16px`,
	},

	title: {
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
		fontSize: rem(36),
		fontWeight: 900,
		lineHeight: 1.1,
		marginBottom: theme.spacing.md,
		color: theme.colorScheme === 'dark' ? theme.white : theme.black,
	},
}));

const features = [
	{
		icon: IconBrandReact,
		title: 'React JS',
		description:
			'React.js is a powerful front-end framework that allows us to create dynamic and interactive web applications with ease, offering exceptional performance and flexibility.',
	},
	{
		icon: IconBrandNextjs,
		title: 'Next JS',
		description:
			'Next.js is a versatile front-end framework built on top of React.js, enabling us to develop fast and SEO-friendly web applications with server-side rendering and a wide range of other useful features.',
	},
	{
		icon: IconBrandPython,
		title: 'Python',
		description:
			'Python and Django are a powerful combination for building high-quality web applications, providing a robust and scalable back-end framework with a rich set of built-in features and a user-friendly syntax that simplifies the development process.',
	},
	{
		icon: IconBrandTypescript,
		title: 'Typescript',
		description:
			'TypeScript and Node.js are an ideal duo for building scalable and high-performance web applications, offering a powerful combination of static type checking and server-side JavaScript execution that enables us to build efficient and reliable back-end systems.',
	},
];

const Technologies = () => {
	const { classes } = useStyles();

	const items = features.map((feature) => (
		<div key={feature.title}>
			<ThemeIcon size={44} radius="md" variant="gradient" gradient={{ deg: 133, from: 'blue', to: 'cyan' }}>
				<feature.icon size={rem(26)} stroke={1.5} />
			</ThemeIcon>
			<Text fz="lg" mt="sm" fw={500}>
				{feature.title}
			</Text>
			<Text c="dimmed" fz="sm">
				{feature.description}
			</Text>
		</div>
	));

	return (
		<Container className={classes.wrapper}>
			<Grid gutter={48}>
				<Col span={12} md={5}>
					<Title className={classes.title} order={2}>
						Stay ahead with latest technologies in software development.
					</Title>
					<Text c="dimmed">
						Stay ahead of the competition with our use of the latest software development technologies. Our solutions
						are built with innovative tools to meet the unique needs of your business.
					</Text>

					<Button variant="gradient" gradient={{ deg: 133, from: 'blue', to: 'cyan' }} size="lg" radius="md" mt="xl">
						Get started
					</Button>
				</Col>
				<Col span={12} md={7}>
					<SimpleGrid cols={2} spacing={30} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
						{items}
					</SimpleGrid>
				</Col>
			</Grid>
		</Container>
	);
};

export default Technologies;

import { createStyles, Overlay, Container, Title, Button, Text, rem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
	hero: {
		position: 'relative',
		backgroundImage: 'url(/assets/bg.png)',
		backgroundBlendMode: 'hard-light',
		backgroundColor: theme.colorScheme === 'dark' ? '#000000' : '#ffffff',
		// filter: 'blur(5px)',
		// backgroundSize: 'cover',
		backgroundPosition: 'center',
		// backgroundRepeat: 'no-repeat',
		[theme.fn.smallerThan('sm')]: {
			backgroundSize: 'cover',
			backgroundPosition: 'bottom left',
		},
	},

	container: {
		height: rem(721),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		paddingBottom: `calc(${theme.spacing.xl} * 6)`,
		zIndex: 1,
		position: 'relative',

		[theme.fn.smallerThan('sm')]: {
			height: rem(500),
			paddingBottom: `calc(${theme.spacing.xl} * 3)`,
		},
	},

	title: {
		// color: theme.white,
		color: theme.colorScheme === 'dark' ? theme.white : '',
		fontSize: rem(60),
		fontWeight: 900,
		lineHeight: 1.1,

		[theme.fn.smallerThan('sm')]: {
			fontSize: rem(40),
			lineHeight: 1.2,
		},

		[theme.fn.smallerThan('xs')]: {
			fontSize: rem(28),
			lineHeight: 1.3,
		},
	},

	description: {
		// color: theme.white,
		color: theme.colorScheme === 'dark' ? theme.white : '',
		maxWidth: 700,

		[theme.fn.smallerThan('sm')]: {
			maxWidth: '100%',
			fontSize: theme.fontSizes.sm,
		},
	},

	control: {
		marginTop: `calc(${theme.spacing.xl} * 1.5)`,

		[theme.fn.smallerThan('sm')]: {
			width: '100%',
		},
	},
}));

const Landing = () => {
	const { classes } = useStyles();

	return (
		<div className={classes.hero}>
			<Overlay
				// gradient={
				// 	colorScheme === 'dark'
				// 		? 'linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)'
				// 		: 'transparent'
				// }
				gradient="transparent"
				opacity={1}
				zIndex={0}
			/>
			<Container className={classes.container}>
				<Title className={classes.title}>Welcome to Infinary Geeks!</Title>
				<Text className={classes.description} size="xl" mt="xl">
					We create innovative software solutions for businesses and individuals with a focus on quality, customer
					service, and the latest technologies.
				</Text>

				<Button variant="gradient" size="xl" radius="xl" className={classes.control}>
					Get started
				</Button>
			</Container>
		</div>
	);
};

export default Landing;

import React from 'react';
import { Container, Group, Title, Text, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
	logo: {
		width: 200,
		height: 200,
		backgroundImage: 'url(/assets/images/logo_full.jpg)',
		backgroundPosition: 'center',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		borderRadius: theme.radius.lg,
		float: 'left',
		marginInlineEnd: 32,
		marginBlockEnd: 16,
	},
}));

const About = () => {
	const { classes } = useStyles();
	return (
		<Container py={60}>
			<Group mb={16}>
				<Title color="brand.5">About Us</Title>
			</Group>
			<div>
				<div className={classes.logo}></div>
				<Text mb={8}>
					Welcome to{' '}
					<Text color="brand.5" weight="bold" display="inline">
						Infinary Geeks Pvt. Ltd.
					</Text>
					, a leading provider of cutting-edge software solutions designed to help businesses of all sizes transform the
					way they work. With a team of highly skilled developers and technology experts, we are committed to delivering
					powerful and innovative software tools that enable our clients to streamline their business processes, improve
					their workflows, and drive their growth and success.
				</Text>
				<Text mb={8}>
					Our mission is to empower businesses with the latest and most advanced technologies, providing them with the
					tools they need to stay ahead of the competition and succeed in today's fast-paced digital world. Whether
					you're looking to build a custom software solution from scratch or need help integrating your existing systems
					with new technologies, our team has the expertise and experience to deliver the results you need.
				</Text>
				<Text mb={8}>
					At{' '}
					<Text color="brand.5" weight="bold" display="inline">
						Infinary Geeks Pvt. Ltd.
					</Text>
					, we believe in building strong and lasting relationships with our clients. We work closely with each of our
					clients to understand their unique needs and challenges, and tailor our solutions to meet their specific
					requirements. Our commitment to quality and customer satisfaction is at the heart of everything we do, and we
					are proud to have helped businesses across a wide range of industries achieve their goals and realize their
					full potential.
				</Text>
				<Text mb={8}>
					Thank you for choosing{' '}
					<Text color="brand.5" weight="bold" display="inline">
						Infinary Geeks Pvt. Ltd.
					</Text>{' '}
					as your trusted partner in software development. We look forward to working with you and helping your business
					thrive.
				</Text>
			</div>
		</Container>
	);
};

export default About;
